import { useSendMessage } from "../services/queries/chatbotQueries";
import { useChatbotStore, useChatStateStore, useMessagesStore, useProductsStore, useUserStore } from "../zustand/store";
import { GuideButtonGroup } from "./Buttons";
import { ChatBubble, ChatStreamingBubble, GreetingBubble, LoadingBubble, TailBubble } from "./ChatBubble";
import { useEffect, useRef, useState } from 'react';
import PreQuestion from "./PreQuestion";
import { CollectionForm, CompletedForm } from "./Form";

const Chat = () => {
    const { messages } = useMessagesStore();
    const { chatbot } = useChatbotStore();
    const { partnerType } = useUserStore(); 
    const { followupQuestion } = useProductsStore();
    const { isChatbotPending, isPreAnswered, isFormSubmitted, isStreaming, displayLocation } = useChatStateStore();
    const chatContainerRef = useRef(null);
    const [isTailVisible, setIsTailVisible] = useState(false);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages, chatbot?.preQuestion, isTailVisible,isFormSubmitted, followupQuestion]);

    useEffect(() => {
        const fastfiveCondition = (partnerType === 'fastfive') && messages.length > 0 && !isChatbotPending && isPreAnswered;
        const otherCondition = messages.length > 0 && !isChatbotPending && !isStreaming;
        const condition = partnerType === 'fastfive' ? fastfiveCondition : otherCondition;

        if (condition) {
            setIsTailVisible(true);
        } else {
            setIsTailVisible(false);
        }
    }, [followupQuestion, messages, isChatbotPending, isPreAnswered, isStreaming]);

    return (
        <div 
            ref={chatContainerRef}
            style={{ width: '100%', flex: 1, position: 'relative', overflow: 'scroll', padding: '8px 0'}}
        >
            {
                messages.length === 0 && 
                <GreetingBubble />
            }
            {
                messages.length > 0 &&
                messages?.map((message, index) => (
                    message.type === 'form' ?
                    <CompletedForm formData={message.message} /> :
                    <ChatBubble key={index} message={message} loading={message.isLoading ? true : false} />
                ))
            }
            {
                isStreaming &&
                <ChatStreamingBubble loading={isChatbotPending}/>
            }
            {
                partnerType === 'fastfive' && chatbot.preQuestion.length !== 0 &&
                <PreQuestion />
            }
            {/* {
                partnerType !== 'fastfive' && messages.length === 0 &&
                <GuideButtonGroup />
            } */}
            {
                isTailVisible &&
                <TailBubble message={followupQuestion} />
            }
            {
                partnerType === 'fastfive' && messages.length > 0 && isFormSubmitted === 'ready' &&
                <CollectionForm />
            }
        </div>
    )
}

export default Chat;