import listStyles from '../../style/styles/Display.module.css';
import styles from '../../style/styles/ProductView.module.css';
import { redirectToPage } from '../../utils/utils';
import { useUserStore, useChatStateStore } from '../../zustand/store';
import { useUserEvent } from '../../services/queries/chatbotQueries';

export const ProductShrinkView = (props) => {
    const { singleProductList, idx } = props;
    const { partnerType, isMobileDevice } = useUserStore();
    const { mutate: postUserEvent } = useUserEvent();
    const { isConsole, isNewProduct } = useChatStateStore();

    return (
        singleProductList.map((product, idx) => {
            if (partnerType === 'fastfive') {
                return (
                    <div key={idx} className={`${styles.ProductShrinkView__productCardWrap} carousel-card ${isNewProduct && listStyles.bounce}`}>
                        {
                            !isMobileDevice ?
                            <div className='spacing-56' /> :
                            <div className='spacing-16' />
                        }
                        <img src={product.imageUrl} alt={product.name} width={34} height={34} />
                        <div className='spacing-12' />
                        <div>
                            <p className='h8 fc-gray-800'>{product.name}</p>
                            <div className='spacing-4' />
                            <p className='h9 fc-gray-500' style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>{product.shortDesc}</p>
                        </div>
                        {
                            !isMobileDevice ?
                            <div className='spacing-56' /> :
                            <div className='spacing-16' />
                        }
                    </div>
                )
            } else {
                return (
                    <div 
                        key={idx} 
                        className={`${styles.ProductShrinkView__productCardWrap} carousel-card ${isNewProduct && listStyles.bounce}`}
                        style={{cursor: product?.productUrl && product?.productUrl.length > 0 ? 'pointer' : 'default'}}
                        onClick={() => {
                            redirectToPage(product?.productUrl, true, isMobileDevice ? false : true);
                            postUserEvent({
                                eventCategory: 'CarouselClicked',
                            });
                        }}
                    >
                        {
                            !isMobileDevice ?
                            <div className='spacing-56' /> :
                            <div className='spacing-16' />
                        }
                        <img src={product.imageUrl} alt={product.name} className={styles.ProductShrinkView__image} />
                        <div className='spacing-12' />
                        <div>
                            <div style={{display: 'flex'}}>
                                <p 
                                    className='h9 fc-gray-800'
                                    style={{
                                        height: '16px',
                                        overflow: 'hidden', 
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >{product.name}</p>
                            </div>
                            <div className='spacing-4' />
                            <div style={{display: 'flex'}}>
                                {
                                    product.discount > 0 &&
                                    <>
                                        <p className='h8 fc-warning'>{product.discount}%</p>
                                        <div className='spacing-4' />
                                    </>
                                }
                                <p className='h8 fc-gray-800'>{product?.price?.toLocaleString()}원</p>
                                <div className='spacing-8' />
                                <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                <div className='spacing-2' />
                                <p className='h9 fc-gray-500'>{product.rate.toFixed(1)}</p>
                                <div className='spacing-8' />
                                {
                                    product.reviewNum > 0 ?
                                    <>
                                        <p className='h9 fc-gray-500'>리뷰 {product.reviewNum}</p>
                                        <div className='spacing-8' />
                                    </> :
                                    <>
                                        <p className='h9 fc-gray-500'>신규</p>
                                        <div className='spacing-8' />
                                    </>
                                }
                            </div>
                        </div>
                        {
                            !isMobileDevice ?
                            <div className='spacing-56' /> :
                            <div className='spacing-16' />
                        }
                    </div>
                )
            }
        })
    )
}