import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useChatbotStore, useUserStore, useUtmStore, useChatStateStore } from "../../zustand/store";
import { useChatbot } from "../../services/queries/chatbotQueries";

const Transaction = () => {
    const navigate = useNavigate();
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const partnerType = useParams().partnerType;
    const partnerId = searchParams.get('ptid');
    const chatUserId = searchParams.get('cuid') || parseInt(Math.random()*1e9);
    const displayLocation = searchParams.get('dp');
    const displayItemId = searchParams.get('it');
    const isConsole = searchParams.get('isConsole') === 'true';
    const utm = {
        source: searchParams.get('utms'),
        medium: searchParams.get('utmm'), 
        campaign: searchParams.get('utmca'),
        content: searchParams.get('utmco'),
        term: searchParams.get('utmt'),
        transitionPage: searchParams.get('tp'),
    };
    // const isFF = false;
    const isFF = (partnerId === '67516ecf103e7e29c75a621e') || (partnerId ==='67615284c5ff44110dbc6613');

    const { setPartnerId, setPartnerType, setChatUserId } = useUserStore();
    const { setChatbot } = useChatbotStore();
    const { setIsConsole, setDisplayLocation, setDisplayItemId, setIsPDPInit } = useChatStateStore();
    const { setUtmParams } = useUtmStore();
    const { data: chatbot, isLoading, error } = useChatbot(partnerId);

    useEffect(() => {
        setPartnerId(partnerId);
        setPartnerType(isFF ? 'fastfive' : partnerType);
        setChatUserId(chatUserId);
        setDisplayLocation(displayLocation);
        setDisplayItemId(displayItemId);
        setIsConsole(isConsole);
        setUtmParams(utm);
        // It should be false when displayLocation is PRODUCT_DETAIL
        setIsPDPInit(displayLocation !== 'PRODUCT_DETAIL');
        if (chatbot && !isLoading) {
            setChatbot(chatbot);
            let chatUrl;
            if (partnerType === 'dlst') {
                chatUrl = `/dlst/${chatUserId}`; 
            } else {
                chatUrl = `/chat/${chatbot.chatbotId}/${chatUserId}`;
            }
            navigate(chatUrl, { replace: true });
        }
    }, [chatbot])
}

export default Transaction;