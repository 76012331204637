export const addComma = (num) => {
	if (num === undefined) return;
	const parts = isNaN(num) ? num : num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

export const getServiceType = (type) => {
	switch (type) {
		case '파트너':
			return '/img/units/carousel-tag-partner.png';
			break;
		case '스토어':
			return '/img/units/carousel-tag-store.png';
			break;
		case '택배배송':
			return '/img/units/carousel-tag-delivery.png';
			break;
		case '편픽':
			return '/img/units/carousel-tag-cu.png';
			break;
		case '신라면세점':
			return '/img/units/carousel-tag-silla.png';
			break;
		default:
			return '';
	}
}

export const getCustomProperty = (clientId) => {
	const apiUrls = JSON.parse(process.env.REACT_APP_API_URLS);
	const apiKey = JSON.parse(process.env.REACT_APP_API_KEYS);


	return {
		clientId: clientId,
		apiUrls: apiUrls[clientId],
		apiKey: apiKey[clientId],
	}
}

export const getKeywordsList = (topKeywords) => {
	let keywordsArr = [];
	let productsObj = {};
	
	topKeywords.map((data, idx) => {
		keywordsArr.push(data.keyword);
		productsObj[data.keyword] = data.top_items
	})
	return {keywordsArr, productsObj}
}

export const replaceAmpersand = (obj) => {
    // 객체의 각 키에 대해 순회
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            // 값이 문자열인 경우 &를 @@으로 치환
            obj[key] = obj[key].replace('@@', '&');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // 값이 객체나 배열인 경우 재귀적으로 함수 호출
            replaceAmpersand(obj[key]);
        }
    }
}

export const redirectToPage = (url, isNewTab = false, closeChat = true) => {
    if (isNewTab) {
        window.open(url, "_blank");
    } else {
		const targetWindow = window.parent;
        const redirectState = {
            redirectState: true,
            redirectUrl: url,
        }
        targetWindow.postMessage(redirectState, "*");
    }
}

export const setColorCode = (colorCode) => {
	if (!colorCode) return;
	const color0 = `rgba(${colorCode[0].red}, ${colorCode[0].green}, ${colorCode[0].blue}, ${colorCode[0].alpha})`;
	const color1 = `rgba(${colorCode[1].red}, ${colorCode[1].green}, ${colorCode[1].blue}, ${colorCode[1].alpha})`;
	const color2 = `rgba(${colorCode[2].red}, ${colorCode[2].green}, ${colorCode[2].blue}, ${colorCode[2].alpha})`;

    document.documentElement.style.setProperty('--color-prim-800', color0);
    document.documentElement.style.setProperty('--color-prim-200', color1);
    document.documentElement.style.setProperty('--color-prim-100', color2);
}

export const validateInput = (type, value) => {
	let exp;
	let res;
	switch (type) {
		case 'email':
			exp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			res = new RegExp(exp).test(value);
			return res;
		case 'password':
			exp = /^[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,16}$/;
			res = new RegExp(exp).test(value);
			return res;
		default:
			res = value.length > 0;
			return false;
	}
}

export const groupProductsUsingReduce = (products, sliceIndex) => {
    return products.reduce((accumulator, current, index) => {
        if (index % sliceIndex === 0) {
            accumulator.push([]);
        }
        accumulator[accumulator.length - 1].push(current);
        return accumulator;
    }, []);
}

export const findObjectPosition = (data, targetItemId) => {
    for (let outerIndex = 0; outerIndex < data.length; outerIndex++) {
        const innerArray = data[outerIndex];
        for (let innerIndex = 0; innerIndex < innerArray.length; innerIndex++) {
            if (innerArray[innerIndex].itemId === targetItemId) {
                return [outerIndex, innerIndex];
            }
        }
    }
    return null;
}

export const logToConsole = (data) => {
	const targetWindow = window.parent;
	const chatLog = {
		textResp: data?.text,
		productResp: data?.product,
		itemIdsReq: data?.itemIds,
		exampleResp: data?.examples,
	}
	targetWindow.postMessage({
		type: 'chatLog',
		chatLog: chatLog,
	}, "*");
}

export const sendInputFocusStatus = (state) => {
	const targetWindow = window.parent;
	const inputFocusState = {
		inputFocusState: state,
	}
	targetWindow.postMessage(inputFocusState, "*");
}

export const sendResetState = () => {
	const targetWindow = window.parent;
	const resetState = {
		resetState: true,
	}
	targetWindow.postMessage(resetState, "*");
}

export const sendCloseRequestState = (state) => {
	const targetWindow = window.parent;
	const closeRequestState = {
		closeRequestState: state,
	}
	targetWindow.postMessage(closeRequestState, "*");
}

export const sendMessageExistence = (state) => {
	const targetWindow = window.parent;
	const messageExistence = {
		messageExistence: state,
	}
	targetWindow.postMessage(messageExistence, "*");
}