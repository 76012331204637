import React, { useEffect, useRef, useState } from 'react';
import styles from '../style/styles/ChatInput.module.css';
import { useResetQuery, useSendMessageWithWebSocket } from '../services/queries/chatbotQueries';
import { useChatStateStore, useMessagesStore, useProductsStore, useChatbotStore, useUserStore } from '../zustand/store';
import { useSendMessage } from '../services/queries/chatbotQueries';
import { sendInputFocusStatus, sendResetState } from '../utils/utils';

const ChatInput = () => {
    const { examples, clearExamples } = useProductsStore();
    const { chatbot } = useChatbotStore();
    const { isMobileDevice } = useUserStore();
    const { isChatbotPending, setIsChatbotPending, currentItemId, isStreaming, setIsStreaming, isFormSubmitted, setIsPDPInit } = useChatStateStore();
    const { mutate: resetQuery } = useResetQuery();
    const { mutate: sendMessageWithWebSocket, onSuccess: onSuccess } = useSendMessageWithWebSocket();
    const [userInput, setUserInput] = useState('');
    const { isPending } = useSendMessage();
    const { messages } = useMessagesStore();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isResetHovered, setIsResetHovered] = useState(false);
    const [isSendHovered, setIsSendHovered] = useState(false);
    const suggestionsScrollRef = useRef(null);
    const animationRef = useRef(null);
    const resumeTimeoutRef = useRef(null);
    const scrollSpeedPixelsPerSecond = 18;
    const [finishedScroll, setFinishedScroll] = useState(false);  // do not start scroll animation again if it is already finished
    const inputFocusRef = useRef(null);

    useEffect(() => {
        if (isMobileDevice) return;
        if (isFormSubmitted === 'ready') return;
        if (!isStreaming && !isChatbotPending) {
            inputFocusRef.current.focus();
        }
    }, [isInputFocused, isStreaming, isChatbotPending]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            if (resumeTimeoutRef.current) {
                clearTimeout(resumeTimeoutRef.current);
            }
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);
    
    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
            handlePauseAnimation();
        };
    }, [messages, examples]);

    const checkOverflow = () => {
        if (suggestionsScrollRef.current) {
            const scrollContentWidth = suggestionsScrollRef.current.scrollWidth;
            const windowWidth = window.innerWidth;
            const isOverflowing = (scrollContentWidth + 32) > windowWidth;
            
            if (isOverflowing) {
                if (animationRef.current) {
                    cancelAnimationFrame(animationRef.current);
                }
                handleResumeAnimation();
            } else {
                handlePauseAnimation();
            }
        }
    };

    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    }

    const handleKeyPress = async (e) => {
        e.stopPropagation();
        if (userInput.trim() === '') return;
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isPending) return;
            if (userInput === '') return;
            if (isChatbotPending) return;
            if (isStreaming) return;
            handleInputBlur();
            setIsStreaming(true);
            // sendMessage(userInput, false);
            sendMessageWithWebSocket({ 
                messageInput: userInput, 
                isChatbot: true, 
                preAnswers: [],
            });
            setUserInput('');
            setIsChatbotPending(true);
        }
    };

    const handleReset = () => {
        if (isChatbotPending) return;
        if (isStreaming) return;
        resetQuery();
        setUserInput('');
        sendResetState();
        setIsPDPInit(true);
        /* when reset, reset suggestions scroll too */
        if (suggestionsScrollRef.current?.parentElement) {
            if (resumeTimeoutRef.current) {
                clearTimeout(resumeTimeoutRef.current);
            }
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            suggestionsScrollRef.current.parentElement.scrollLeft = 0;
            setFinishedScroll(false);
            checkOverflow();
        }
    }

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
        sendInputFocusStatus(true);
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendInputFocusState) {
            window.webkit.messageHandlers.sendInputFocusState.postMessage(`input focused`);
        } else {
            console.warn('webkit.messageHandlers is not available');
        }

        // AOS SDK로 메시지 전달
        if (window.Android && window.Android.sendInputFocusState) {
            window.Android.sendInputFocusState('input focused');
        } else {
            console.warn('Android interface is not available');
        }
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
        sendInputFocusStatus(false);
        if (isMobileDevice) {
            inputFocusRef.current.blur();
        }
    }

    const animateScroll = (startTime) => {
        if (!suggestionsScrollRef.current?.parentElement) return;

        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const scrollPosition = (elapsedTime / 1000) * scrollSpeedPixelsPerSecond;

        suggestionsScrollRef.current.parentElement.scrollTo({
            left: scrollPosition,
            behavior: 'auto'
        });

        if (scrollPosition < suggestionsScrollRef.current.scrollWidth + 32 - window.innerWidth) {
            animationRef.current = requestAnimationFrame(() => animateScroll(startTime));
        } else {
            setFinishedScroll(true);
            handlePauseAnimation();
        }
    };

    const handleResumeAnimation = () => {
        if (finishedScroll) return;
        // Clear any existing timeout first
        if (resumeTimeoutRef.current) {
            clearTimeout(resumeTimeoutRef.current);
        }
        resumeTimeoutRef.current = setTimeout(() => {
            if (suggestionsScrollRef.current?.parentElement) {
                const currentScroll = suggestionsScrollRef.current.parentElement.scrollLeft;
                const startTime = Date.now() - (currentScroll / scrollSpeedPixelsPerSecond * 1000);
                animateScroll(startTime);
            }
        }, 2000);
    };

    const handlePauseAnimation = () => {
        if (resumeTimeoutRef.current) {
            clearTimeout(resumeTimeoutRef.current);
        }
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
    };

    return (
        <div className={styles.ChatInput}>
            <div 
                className={`${isMobileDevice ? styles.ChatInput__suggestions__mobile : styles.ChatInput__suggestions__desktop}`}
                // Mouse events for scrollbar interaction
                onMouseDown={() => {
                    if (!isMobileDevice) {
                        handlePauseAnimation();
                    }
                }}
                onMouseUp={() => {
                    if (!isMobileDevice) {
                        handleResumeAnimation();
                    }
                }}
                onMouseLeave={() => {
                    if (!isMobileDevice) {
                        handleResumeAnimation();
                    }
                }}
            >
                <div 
                    className={styles.ChatInput__suggestions__scrollContent} 
                    ref={suggestionsScrollRef}
                    // Mouse events for desktop
                    onMouseEnter={handlePauseAnimation}
                    onMouseLeave={handleResumeAnimation}
                    // Touch events for mobile
                    onTouchStart={handlePauseAnimation}
                    onTouchEnd={handleResumeAnimation}
                    // Scroll events for both
                    onScroll={(e) => {
                        e.stopPropagation();
                        handlePauseAnimation();
                        handleResumeAnimation();
                    }}
                >
                    {
                        messages.length === 0 ?
                        !isChatbotPending && !isStreaming && chatbot?.examples?.map((example, index) => (
                            <div
                            key={index}
                            className={styles.ChatInput__suggestions__item}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <p
                                className='h3'
                                onClick={(e) => {
                                    setIsStreaming(true);
                                    sendMessageWithWebSocket({ messageInput: example, isChatbot: false, preAnswers: [] });
                                    setIsChatbotPending(true);
                                    sendInputFocusStatus(true);
                                    clearExamples();
                                }}
                            >{example}</p>
                        </div>))
                        :
                        !isChatbotPending && !isStreaming && examples?.map((example, index) => (
                            <div
                                key={index}
                                className={styles.ChatInput__suggestions__item}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <p
                                    className='h3'
                                    onClick={(e) => {
                                        setIsStreaming(true);
                                        sendMessageWithWebSocket({ messageInput: example, isChatbot: false, preAnswers: [] });
                                        setIsChatbotPending(true);
                                        sendInputFocusStatus(true);
                                        clearExamples();
                                    }}
                                >{example}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.ChatInput__inputContainer}>
                <div className={styles.ChatInput__inputContainer__wrapper}>
                    <img
                        src={`${isChatbotPending || isStreaming ? '/img/units/refresh-disabled.png' : (isResetHovered ? '/img/units/refresh-hover.png' : '/img/units/refresh-active.png')}`}
                        // className={`${styles.resetIcon} ${isChatbotPending && 'non-clickable'}`} 
                        className={`${styles.ChatInput__resetIcon} ${(isChatbotPending || isStreaming) && 'non-clickable'}`}
                        alt="Reset input"
                        onClick={handleReset}
                        onMouseEnter={() => !isChatbotPending && setIsResetHovered(true)}
                        onMouseLeave={() => !isChatbotPending && setIsResetHovered(false)}
                    />
                    <div className='spacing-8' />
                    <div 
                        className={styles.ChatInput__inputWrapper}
                    >
                        <input
                            ref={inputFocusRef}
                            type='text'
                            className={styles.ChatInput__input}
                            placeholder={"무엇이든 물어보세요"}
                            value={userInput}
                            onChange={handleUserInputChange}
                            onKeyUp={handleKeyPress}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                        <img
                            src={`${(isChatbotPending || isStreaming) ? '/img/units/loading-spinner-28px.gif' : (userInput === '' ? '/img/units/send-disabled.png' : (isSendHovered ? '/img/units/send-hover.png' : '/img/units/send-active.png'))}`}
                            className={`${styles.ChatInput__sendIcon} ${(isChatbotPending || (userInput === '')) && 'non-clickable'}`}
                            alt="Send message"
                            onClick={
                                () => {
                                    if (userInput.trim() === '') return;
                                    if (isChatbotPending) return;
                                    if (isStreaming) return;
                                    setIsStreaming(true);
                                    sendMessageWithWebSocket({ messageInput: userInput, isChatbot: false, preAnswers: [] });
                                    setUserInput('');
                                    setIsChatbotPending(true);
                                }
                            }
                            onMouseEnter={() => !isChatbotPending && userInput !== '' && setIsSendHovered(true)}
                            onMouseLeave={() => !isChatbotPending && userInput !== '' && setIsSendHovered(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatInput;