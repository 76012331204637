import listStyles from '../../style/styles/Display.module.scss';

export const productInfoTypeVertical = (product, type) => {
    switch (type) {
        case 0: // default
            return (
                <div className={listStyles.ProductList__productInfoContainer}>
                    <div className={listStyles.ProductList__productHeader}>
                        <p className={`fc-gray-800 h9 line-clamp-2`}>{product.name}</p>
                    </div>
                    <div className='spacing-2' />
                    <div className={listStyles.ProductList__productDetails}>
                        {
                            product?.discount > 0 &&
                            <>
                                <div className={listStyles.ProductList__discountRate}>{product?.discount?.toLocaleString()}%</div>
                                <div className='spacing-2' />
                            </>
                        }
                        <div className={listStyles.ProductList__price}>{product?.price?.toLocaleString()}원</div>
                    </div>
                </div>
            )
        case 1: // fastfive
            return (
                <div className={listStyles.ProductList__productInfoContainer}>
                    <div className={listStyles.ProductList__productHeader}>
                        <p className={`fc-gray-800 h8 line-clamp-1`}>{product.name}</p>
                    </div>
                    <div className='spacing-2' />
                    <div className={listStyles.ProductList__productDetails}>
                        <p className={`fc-gray-500 h9 line-clamp-2`}>{product.shortDesc}</p>
                    </div>
                </div>
            )
        default:
            return (
                <div className={listStyles.ProductList__productInfoContainer}>
                    <div className={listStyles.ProductList__productHeader}>
                        <p className={`fc-gray-800 h9 line-clamp-2`}>{product.name}</p>
                    </div>
                    <div className='spacing-2' />
                    <div className={listStyles.ProductList__productDetails}>
                        {
                            product?.discount > 0 &&
                            <>
                                <div className={listStyles.ProductList__discountRate}>{product?.discount?.toLocaleString()}%</div>
                                <div className='spacing-2' />
                            </>
                        }
                        <div className={listStyles.ProductList__price}>{product?.price?.toLocaleString()}원</div>
                    </div>
                </div>
            )
    }
}

export const productInfoTypeHorizontal = (product, type) => {
    switch (type) {
        default:
            return (
                <div className={listStyles.ProductList__productInfoContainer}>
                    <div className={listStyles.ProductList__productHeader}>
                        <p className={`fc-gray-800 h9 line-clamp-1`}>{product.name}</p>
                    </div>
                    <div className='spacing-4' />
                    <div className={listStyles.ProductList__productDetails}>
                        {
                            product?.discount > 0 &&
                            <>
                                <div className={listStyles.ProductList__discountRate}>{product?.discount?.toLocaleString()}%</div>
                                <div className='spacing-2' />
                            </>
                        }
                        <div className={listStyles.ProductList__price}>{product?.price?.toLocaleString()}원</div>
                        <div className='spacing-8' />
                        <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                        <div className='spacing-2' />
                        <p className='h9 fc-gray-500'>{product.rate.toFixed(1)}</p>
                        <div className='spacing-4' />
                        {
                            product.reviewNum > 0 ?
                            <>
                                <p className='h9 fc-gray-500'>리뷰 {product.reviewNum}</p>
                            </> :
                            <>
                                <p className='h9 fc-gray-500'>신규</p>
                            </>
                        }
                    </div>
                </div>
            )
    }
}