import { useUserEvent } from '../../services/queries/chatbotQueries';
import listStyles from '../../style/styles/Display.module.css';
import styles from '../../style/styles/ProductView.module.css';
import { redirectToPage } from '../../utils/utils';
import { useUserStore, useChatStateStore } from '../../zustand/store';
import { productInfoTypeVertical, productInfoTypeHorizontal } from './ProductInfoType';

export const CarouselListVertical = (props) => {
    const { products, idx } = props;
    const { isMobileDevice, partnerType } = useUserStore();
    const { mutate: postUserEvent } = useUserEvent();
    const { isConsole, isNewProduct } = useChatStateStore();
    const productInfoType = partnerType === 'fastfive' ? 1 : 0;

    return (
        <div key={idx} className={`${listStyles.ProductListVertical__productList} carousel-card ${isNewProduct && listStyles.bounce}`}>
            {products.map((product, productIdx) => (
                <div 
                    key={productIdx} 
                    className={listStyles.ProductListVertical__productItem}
                    style={{cursor: product?.productUrl && product?.productUrl.length > 0 ? 'pointer' : 'default'}}
                    onClick={() => {
                        if (product?.productUrl && product?.productUrl.length > 0) {
                            redirectToPage(
                                product?.productUrl, 
                                isConsole,
                                isMobileDevice
                            );
                            postUserEvent({
                                eventCategory: 'CarouselClicked',
                            });
                        }
                    }}
                >
                    <div className={listStyles.ProductListVertical__productImageWrapper}>
                        <img 
                            className={listStyles.ProductListVertical__productImage} 
                            src={product.imageUrl} 
                            alt={product.name} 
                        />
                    </div>
                    <div className='spacing-2'/>
                    {productInfoTypeVertical(product, productInfoType)}
                </div>
            ))}
        </div>
    )
}

export const CarouselListHorizontal = (props) => {
    const { products, idx } = props;
    const { isMobileDevice, partnerId } = useUserStore();
    const { isConsole, isNewProduct } = useChatStateStore();
    const { mutate: postUserEvent } = useUserEvent();

    const productInfoType = 0;  // default

    return (
        <div key={idx} className={`${listStyles.ProductListHorizontal__productList} carousel-card ${isNewProduct && listStyles.bounce}`}>
            {products.map((product, productIdx) => (
                <div 
                    key={productIdx} 
                    className={listStyles.ProductListHorizontal__productItem}
                    style={{cursor: product?.productUrl && product?.productUrl.length > 0 ? 'pointer' : 'default'}}
                    onClick={() => {
                        if (product?.productUrl && product?.productUrl.length > 0) {
                            redirectToPage(
                                product?.productUrl, 
                                isConsole,
                                isMobileDevice
                            );
                            postUserEvent({
                                eventCategory: 'CarouselClicked',
                            });
                        }
                    }}
                >
                    <div className={listStyles.ProductListHorizontal__productImageWrapper}>
                        <img 
                            className={listStyles.ProductListHorizontal__productImage} 
                            src={product.imageUrl} 
                            alt={product.name} 
                        />
                    </div>
                    <div className='spacing-12'/>
                    {productInfoTypeHorizontal(product, productInfoType)}
                </div>
            ))}
        </div>
    )
    
}


export const CarouselSingle = (props) => {
    const { products, idx } = props;
    const { isMobileDevice, partnerId } = useUserStore();
    const { isConsole, isNewProduct } = useChatStateStore();
    const { mutate: postUserEvent } = useUserEvent();

    return (
        products.map((product, idx) => {
            return (
                <div 
                    className={`${styles.ProductCard} ${!isMobileDevice && styles.ExtraPadding__horizontal} carousel-card ${isNewProduct && listStyles.bounce}`} 
                    key={idx}
                    onClick={() => {
                        redirectToPage(
                            product?.productUrl, 
                            isConsole,
                            isMobileDevice
                        );
                        postUserEvent({
                            eventCategory: 'CarouselClicked',
                        });
                    }}
                >
                    <img
                        src={product?.imageUrl}
                        alt={product?.name}
                        className={styles.ProductCard__productImage}
                    />
                    <div className='spacing-16' />
                    <div className={styles.ProductCard__productInfo}>
                        <div className={styles.ProductCard__productName}>{product?.name}</div>
                        <div className='spacing-4' />
                        <div className={styles.ProductCard__priceWrapper}>
                            {
                                product?.discount > 0 &&
                                <>
                                    <div className={styles.ProductCard__discountRate}>{product?.discount?.toLocaleString()}%</div>
                                    <div className='spacing-2' />
                                </>
                            }
                            <div className={styles.ProductCard__productPrice}>{product?.price?.toLocaleString()}원</div>
                        </div>
                        <div className='spacing-4' />
                        <div className={styles.ProductCard__reviewWrapper}>
                            {
                                product?.rate > 0 ?
                                    <>
                                        <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                        <div className='spacing-2' />
                                        <p className={styles.ProductCard__reviewScore}>{product?.rate.toFixed(1)}</p>
                                        <div className='spacing-4' />
                                        <p className={styles.ProductCard__reviewScore}>리뷰 {product?.reviewNum}</p>
                                    </> :
                                    <>
                                        <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                        <div className='spacing-2' />
                                        <p className={styles.ProductCard__reviewScore}>신규</p>
                                    </>
                            }
                        </div>
                        <div className='spacing-4' />
                        <div 
                            className={styles.ProductCard__detailButton}
                        >
                            <p 
                                className={styles.ProductCard__detailButtonText}
                            >자세히 보기</p>
                        </div>
                    </div>
                </div>
            )
        })
    )
}