import { useState, useEffect } from 'react';
import Display from '../Display';
import Chat from '../Chat';
import ChatInput from '../ChatInput';
import { useChatbotStore, useChatStateStore, useMessagesStore, useProductsStore, useUserStore, useUtmStore } from '../../zustand/store';
import { useChatbot, useLoadHistory, useSendPDPMessage } from '../../services/queries/chatbotQueries';
import { sendInputFocusStatus, sendMessageExistence, sendResetState, setColorCode } from '../../utils/utils';
import { useParams, useSearchParams } from 'react-router-dom';

const ChatFieldBase = (props) => {    
    const { chatbot } = useChatbotStore();
    const { products } = useProductsStore();
    const { isPreAnswered, setIsConsole, isConsole, displayLocation, setDisplayLocation, setDisplayItemId, isPDPInit, setIsPDPInit, setIsNewProduct } = useChatStateStore();
    const { setUtmParams } = useUtmStore();
    const { partnerType, setPartnerId, setChatUserId, setIsMobileDevice, setPartnerType } = useUserStore();
    const { refetch: refetchLoadHistory } = useLoadHistory();
    const [ mode, setMode ] = useState('gentooGreeting');
    const [ isDisplayVisible, setIsDisplayVisible ] = useState(false);
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const chatBotId = useParams().chatBotId;
    const lyclePartnerId = searchParams.get('ptid');
    const { refetch: refetchChatbot } = useChatbot(lyclePartnerId);
    const { mutate: sendPDPMessage } = useSendPDPMessage();
    const { messages, streamMessage } = useMessagesStore();
    let isPDP;
    
    
    useEffect(() => {
        if (isConsole) return;
        if (chatBotId === '49' || chatBotId === '153') {
            const lycleChatUserId = searchParams.get('cuid') || Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            const lycleIsMobileDevice = searchParams.get('ch');
            const lycleDisplayLocation = searchParams.get('dp');
            const lycleDisplayItemId = searchParams.get('it');
            const lycleIsConsole = searchParams.get('isConsole') === 'true';
            const lycleUtm = {
                utms: searchParams.get('utms'),
                utmm: searchParams.get('utmm'),
                utmca: searchParams.get('utmca'),
                utmco: searchParams.get('utmco'),
                utmt: searchParams.get('utmt'),
                tp: searchParams.get('tp'),
            }
            setPartnerId(lyclePartnerId);
            setPartnerType('gentoo');
            setChatUserId(lycleChatUserId);
            setIsMobileDevice(lycleIsMobileDevice);
            setDisplayLocation(lycleDisplayLocation);
            setDisplayItemId(lycleDisplayItemId);
            setIsConsole(lycleIsConsole);
            setUtmParams(lycleUtm);
            setIsPDPInit(lycleDisplayLocation !== 'PRODUCT_DETAIL');
            if (lycleDisplayLocation === 'PRODUCT_DETAIL') {
                // sendPDPMessage();
                isPDP = true;
            }
        } else {
            if (displayLocation === 'PRODUCT_DETAIL') {
                // sendPDPMessage();
                isPDP = true;
            }
        }

        // postMessage event listener
        window.addEventListener('message', (e) => {
            if (e.data.buttonClickState === true && e.data.clickedElement === 'floatingContainer') {
                if (isPDP) {
                    sendPDPMessage();
                }
            } else if (e.data.buttonClickState === true && (e.data.clickedElement === 'closeButtonContainer' || e.data.clickedElement === 'closeActionArea')) {
                setIsNewProduct(false);
            }
        })
    }, [])

    useEffect(() => {
        if (partnerType === 'fastfive' && !isPreAnswered) {
            setIsDisplayVisible(false);
        } else if (messages.length === 0 && streamMessage.length === 0) {
            setIsDisplayVisible(false);
        } else {
            setIsDisplayVisible(true);
        }
    }, [messages, streamMessage, isPreAnswered]);

    useEffect(() => {
        if (messages.length === 0 || messages[messages.length - 1]?.type !== 'pdp') {
            sendMessageExistence('none');
        } else if (messages.length > 0) {
            sendMessageExistence('exist');
        }
    }, [messages]);

    useEffect(() => {
        refetchLoadHistory();
        setColorCode(chatbot?.colorCode);
        setIsMobileDevice(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, [chatbot]);

    useEffect(() => {
        if (products.length > 0) {
            if (partnerType === 'fastfive') {
                setMode('productVerticalList');
            } else {
                setMode(chatbot?.carouselType === 'single' ? 'productSingle' : (chatbot?.carouselType === 'carousel-horizontal' ? 'productHorizontalList' : 'productVerticalList') || 'productSingle');
            }
        } else {
            setMode('gentooGreeting');
        }
    }, [products]);

    return (
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Chat />
            {   
                isDisplayVisible &&
                <Display mode={mode} />
            }
            {
                partnerType !== 'fastfive' || isPreAnswered ?
                <ChatInput /> :
                <div style={{ width: '100%', height: '86px'}}>
                </div>
            }
        </div>
    );
};

export default ChatFieldBase