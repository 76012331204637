import { useChatbotStore, useChatStateStore, useMessagesStore, useUserStore } from "../zustand/store";
import MarkDownConverter from "./Markdown";
import styles from '../style/styles/ChatBubble.module.css';
import buttonStyles from '../style/styles/Buttons.module.css';
import { useEffect, useState } from "react";
import { Skeleton } from "./Chat/Skeleton";

export const ChatBubble = (props) => {
    const { message, loading } = props;
    const { chatbot } = useChatbotStore();
    const imageUrl = chatbot?.profileImg || '/img/units/gentoo-symbol.png';

    /* <div className={styles.Bubble__chatbotLoadingMessageWrapper}>
        <Skeleton />
    </div>  */
    /*     <div className={styles.Bubble__chatbotMessageWrapper}>
            <img src="/img/units/loading-typing-animation.gif" width={39} height={19} />
        </div> */
    if (message.message.length === 0) return null;
    else {
        return (
            message.isChatbot ?
            <div className={styles.Bubble__wrapper}>
                <img src={imageUrl} width={32} height={32} />
                <div className='spacing-8' />
                <div style={{ width: '100%' }}>
                    <p className={styles.Bubble__name}>{chatbot?.name}</p>
                    <div className='spacing-4' />
                    <div className={styles.Bubble__chatbotMessageWrapper}>
                        <p className={styles.Bubble__chatbotMessage}><MarkDownConverter text={message.message} /></p>
                    </div>
                </div>
            </div> :
            <div className={styles.Bubble__myWrapper}>
                <div className={styles.Bubble__myMessageWrapper}>
                    <p className={styles.Bubble__myMessage}>{message.message}</p>
                </div>
            </div>
        )
    }
}

export const ChatStreamingBubble = (props) => {
    const { loading } = props;
    const { chatbot } = useChatbotStore();
    const { streamMessage } = useMessagesStore();
    const imageUrl = chatbot?.profileImg || '/img/units/gentoo-symbol.png';
    const [message, setMessage] = useState('');
    /* <div className={styles.Bubble__chatbotLoadingMessageWrapper}>
        <Skeleton />
    </div>  */
    /*     <div className={styles.Bubble__chatbotMessageWrapper}>
            <img src="/img/units/loading-typing-animation.gif" width={39} height={19} />
        </div> */

    useEffect(() => {
        // document.getElementById('stream-response').innerHTML = streamMessage;
        const streamResponse = document.getElementById('stream-response');
        if (streamResponse) {
            streamResponse.innerHTML = streamMessage;
        }
    }, [streamMessage]);


    return (
        <div className={styles.Bubble__wrapper}>
            <img src={imageUrl} width={32} height={32} />
            <div className='spacing-8' />
            <div style={{ width: '100%' }}>
                <p className={styles.Bubble__name}>{chatbot?.name}</p>
                <div className='spacing-4' />
                {
                    loading ?
                    <LoadingBubble /> :
                    <div className={styles.Bubble__chatbotMessageWrapper}>
                        <p id='stream-response' className={styles.Bubble__chatbotMessage}><MarkDownConverter text={message} /></p>
                    </div>
                }
            </div>
        </div>
    )
}

export const GreetingBubble = () => {
    const { chatbot } = useChatbotStore();
    const imageUrl = chatbot?.profileImg || '/img/units/gentoo-symbol.png';
    const { isPDPInit } = useChatStateStore();

    return (
        <div className={styles.Bubble__wrapper}>
            <img src={imageUrl} width={32} height={32} />
            <div className='spacing-8' />
            <div>
                <p className={styles.Bubble__name}>{chatbot?.name}</p>
                <div className='spacing-4' />
                {
                    !isPDPInit ?
                    <LoadingBubble /> :
                    <div className={styles.Bubble__chatbotMessageWrapper}>
                        <p className={styles.Bubble__chatbotMessage}>{chatbot?.greetingMessage}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export const TailBubble = (props) => {
    const { message } = props;
    const { setIsFormSubmitted } = useChatStateStore();
    const { partnerType } = useUserStore();

    return (
        message?.length > 0 &&
        <>
            <div className={buttonStyles.TailBubble__wrapper}>
                <div className='spacing-32' />
                <div className='spacing-8' />
                <div>
                    <div className={styles.Bubble__chatbotMessageWrapper}>
                        <p className={styles.Bubble__chatbotMessage}>{message}</p>
                    </div>
                </div>
            </div>
            {
                partnerType === 'fastfive' &&
                <div className={buttonStyles.TailBubble__buttonWrapper}>
                    <div className='spacing-32' />
                    <div className='spacing-8' />
                    <button
                        className={buttonStyles.TailBubble__button}
                        onClick={() => setIsFormSubmitted('ready')}
                    >
                        <p className="h4 fc-white">💥 사무실 최저가 견적 받기</p>
                    </button>
                </div>
            }
        </>
    )
}

export const LoadingBubble = () => {
    return (
        <div className={styles.Bubble__chatbotMessageWrapper}>
            <div className={styles.typingIndicator}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}