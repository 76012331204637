import { useEffect, useState } from "react";
import FloatingButton from "./FloatingButton"
import FloatingButtonComp from "./FloatingButton";
import compStyle from '../style/styles/Components.module.css';
import mockupStyle from '../style/styles/Mockup.module.css';
import GentooService from "./SDK/GentooService";

export const MockupDLST = () => {
    let browserWidth = window.innerWidth;
    const [isSmallResolution, setIsSmallResolution] = useState(browserWidth < 601);
    const [visibleGuide, setVisibleGuide] = useState(true);
    const chatUserId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    window.addEventListener('resize', () => {
        browserWidth = window.innerWidth;
        setIsSmallResolution(browserWidth < 601);
    })

    const handleClickGuideArea = () => {
        setVisibleGuide(false);
    }

    useEffect(() => {
        GentooService.loadScript()
            .then(() => {
                GentooService.boot({
                    partnerId: '6718be2310310e41ab5276ef',
                    authCode: chatUserId,
                    itemId: '',
                    displayLocation: "HOME",
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div
            id='gentoo-sc' 
            style={{
                width: '100%',
                height: '100%',
                overflowY: 'scroll',
            }}
        >
            {/* Need to make floating button lower than guide image */}
            <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '56px', background: '#444', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', width: '100%', maxWidth: '800px', padding: '0 16px', justifyContent: 'center'}}>
                    <img src="/img/units/gentoo-symbol.png" width={32} height={32} />
                    <div className={compStyle.Spacing__16} />
                    <div>
                        <p className="h5 fc-white">실제 페이지가 아닌 데모 페이지입니다.</p>
                        <p className="h6 fc-white">술과 관련된 대화를 나눠보세요.</p>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', minHeight: '56px'}} />
            {
                visibleGuide ?
                <div style={{position: 'fixed', top: 56, left: 0, width: '100%', height: '100%', zIndex: 4}}>
                    <div 
                        style={{width: '100%', height: '100%', background: 'rgba(34, 34, 34, 0.7)'}}
                        onClick={handleClickGuideArea}
                    >
                        <img src="/img/units/close.png" width={32} height={32} style={{position: 'fixed', top: 80, right: 24, cursor: 'pointer'}} />
                        <div className={mockupStyle.Guide__img}>
                            <img src="/img/units/guide-floating.png" style={{width: '320px', height: '182px'}}/>
                        </div> 
                    </div>
                </div>:
                GentooService.init()
            }
            <img
                id='img-sc'
                src={isSmallResolution ? "/img/dlst-mockup-md.png" : "/img/dlst-mockup-web.png"}
                width={'100%'}
            />
            <div className={mockupStyle.Mockup__buttonWrap}>
                <div 
                    onClick={() => {
                        if (visibleGuide) return;
                        window.location.href = 'https://dailyshot.co/m/map/seller?item=199694&lat=37.652921&lon=126.892742&seller=16777';
                    }}
                    className={mockupStyle.Mockup__button}
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src='/img/units/sdk-pickup-icon.png' width={21} height={18} />
                        <div className={compStyle.Spacing__4} />
                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '900'}}>방문픽업 주문하기</p>
                    </div>
                </div>
            </div>
        </div>
    )
}