import styles from '../../style/styles/Components.module.css';

export const PaginationDots = ({ totalDots = 0, activeDotIndex = 0, maxDots = 6 }) => {
  if (totalDots === 0) return null;

  const visibleDots = Math.min(totalDots, maxDots);
  const isActiveDot = Math.min(activeDotIndex, visibleDots - 1);

  return (
    <div
      style={{
        display: 'flex',
        gap: '6px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {Array.from({ length: visibleDots }).map((_, index) => (
        <div
          key={index}
          style={{
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: index === isActiveDot ? '#999' : '#e1e1e1',
            transition: 'background-color 0.3s ease',
          }}
        />
      ))}
    </div>
  );
};

export const LineIndicator = (props) => {
  const { maxValue = 100, value = 10 } = props;

  if (maxValue === 0) return null;

  const isActiveDot = Math.min(value, maxValue - 1);
  const lineWidth = (value + 1) / maxValue * 100;

  return (
    <div
      className={styles.Indicator__line__container}
    >
      {Array.from({ length: maxValue }).map((_, index) => (
        <div
          key={index}
          className={styles.Indicator__line__dot}
          style={{
            width: `${lineWidth}%`,
            borderRadius: index === isActiveDot ? '999px' : '0px',
            backgroundColor: index === isActiveDot ? '#999' : '#e1e1e1',
          }}
        />
      ))}
    </div>
  )
}