import { GentooGreetingView } from "./GentooView";
import styles from '../style/styles/Display.module.css';
import { useEffect, useState } from "react";
import { CarouselContainer } from "./Carousel/CarouselContainer";
import { useChatStateStore, useMessagesStore, useUserStore } from "../zustand/store";
import { sendCloseRequestState } from "../utils/utils";

const Display = (props) => {
    const { mode, isPDP } = props;
    const { isFormSubmitted, isNewProduct, setIsNewProduct, newProductCount, isPDPInit } = useChatStateStore();
    const { isMobileDevice } = useUserStore();
    const { messages } = useMessagesStore();
    const [isDisplayOpen, setIsDisplayOpen] = useState((messages[messages.length - 1]?.type === 'pdp' && isMobileDevice) ? false : true);

    const getDisplayContent = () => {
        switch (mode) {
            case 'gentooGreeting':
                return <GentooGreetingView isDisplayOpen={isDisplayOpen} />;
            case 'productSingle':
                return <CarouselContainer isDisplayOpen={isDisplayOpen} type={'single'} />;
            case 'productHorizontalList':
                return <CarouselContainer isDisplayOpen={isDisplayOpen} type={'horizontal'} />;
            case 'productVerticalList':
                return <CarouselContainer isDisplayOpen={isDisplayOpen} type={'vertical'} />;
            default:
                return <GentooGreetingView isDisplayOpen={isDisplayOpen} />;
        }
    }

    useEffect(() => {
        if (isFormSubmitted === 'ready') {
            setIsDisplayOpen(false);
        }
    }, [isFormSubmitted]);

    useEffect(() => {
        const handleUserInteraction = () => {
            setIsNewProduct(false);
        };

        if (isNewProduct) {
            document.addEventListener('click', handleUserInteraction);
            document.addEventListener('touchstart', handleUserInteraction);
            document.addEventListener('keydown', handleUserInteraction);
        }

        return () => {
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('touchstart', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };
    }, [isNewProduct, setIsNewProduct]);

    useEffect(() => {
        let startY = 0;
        let isDragging = false;

        const handleTouchStart = (e) => {
            startY = e.touches[0].clientY;
            isDragging = true;
        };

        const handleTouchMove = (e) => {
            if (!isDragging) return;
            const currentY = e.touches[0].clientY;
            const diffY = currentY - startY;

            if (diffY > 80) { // Threshold for detecting a downward drag
                sendCloseRequestState(true);
                // Add your logic here for what should happen on a downward drag
                isDragging = false; // Reset dragging state
            }
        };

        const handleTouchEnd = () => {
            isDragging = false;
        };

        const displayElement = document.querySelector(`.${styles.Display__Container}`);
        displayElement.addEventListener('touchstart', handleTouchStart);
        displayElement.addEventListener('touchmove', handleTouchMove);
        displayElement.addEventListener('touchend', handleTouchEnd);

        return () => {
            displayElement.removeEventListener('touchstart', handleTouchStart);
            displayElement.removeEventListener('touchmove', handleTouchMove);
            displayElement.removeEventListener('touchend', handleTouchEnd);
        };
    }, [styles.Display__Container]);

    return (
        <div className={styles.Display__Container}>
            <div className={styles.TopHeader__container}>
                <div className={styles.TopHeader__divider} />
            </div>
            {getDisplayContent()}
            <div className={styles.Display__ToggleButtonContainer}>
                <img 
                    src={isDisplayOpen ? '/img/display/display-close.png' : '/img/display/display-open.png'} 
                    width={24} 
                    height={24} 
                    onClick={() => setIsDisplayOpen(!isDisplayOpen)} 
                    alt='close'
                    className={styles.Display__ToggleButton}
                />
            </div>
            <div className={styles.Display__CarouselAlarmBadge} style={{ visibility: isNewProduct ? 'visible' : 'hidden' }}>
                <p className='h10 fc-white'>새로운 상품 +{newProductCount}</p>
            </div>
        </div>
    )
}

export default Display;