import { useQuery, useMutation } from "@tanstack/react-query";
import { chatApi } from "../api";
import { useChatbotStore, useChatStateStore, useMessagesStore, useProductsStore, useUserStore, useUtmStore } from "../../zustand/store";
import { groupProductsUsingReduce, logToConsole } from "../../utils/utils";
import { useState } from "react";

export const useChatbot = (partnerId) => {
    const { setChatbot } = useChatbotStore();
    return useQuery({
        queryKey: ['chatbot', partnerId],
        queryFn: async () => {
            const response = await chatApi.get(process.env.REACT_APP_CHATBOT_ENDPOINT + `/${partnerId}`);
            setChatbot(response.data);
            return response.data;
        },
        enabled: !!partnerId,
    });
};

export const useMessages = () => {
    const { partnerId, chatUserId, isMobileDevice } = useUserStore();
    const { products, setProducts, clearProducts, structuredProducts, setStructuredProducts } = useProductsStore();
    const { setMessages, clearMessages } = useMessagesStore();
    const { currentItemId, setCurrentItemId, setIsChatbotPending, setIsNewProduct, setNewProductCount } = useChatStateStore();
    const { setIsFormSubmitted } = useChatStateStore();
    const { refetch: refetchExample } = useExampleQuery();

    return useMutation({
        mutationFn: async (variables) => {
            try {
                setIsFormSubmitted('initial');
                const { message, itemId, preAnswers } = variables;
                const response = await chatApi.post(process.env.REACT_APP_CHAT_ENDPOINT + `/${partnerId}`, {
                    requestMessage: String(message),
                    itemIds: itemId,
                    chatUserId: String(chatUserId),
                    channelId: isMobileDevice ? 'mobile' : 'web',
                    preAnswers: preAnswers,
                });
                
                if (response.data.text) {
                    // setMessages([{ message: response.data.text[0], isChatbot: true }])
                    logToConsole(response.data);
                    if (response.data.product.length > 0) {
                        const newProducts = [...response.data.product, ...products];
                        const newStructuredProducts = groupProductsUsingReduce(response.data.product, 3);
                        clearProducts();
                        setProducts(newProducts);
                        setStructuredProducts(newStructuredProducts);
                        setIsNewProduct(true);
                        setNewProductCount(response.data.product.length);
                    }
                    setIsChatbotPending(false);
                    if (currentItemId.length > 0) {
                        refetchExample();
                    }
                    return response.data;
                }
            } catch (error) {
                setMessages([{ message: '네트워크 에러가 발생했습니다. 다시 시도해주세요.', isChatbot: true }])
                logToConsole({textResp: error.message});
                setIsChatbotPending(false);
                console.log('error', error.message);
            }
        },
    });
};

// custom hook for sending message
export const useSendMessage = () => {
	const { mutate: sendMessages, isPending, isSuccess } = useMessages();
	const { currentItemId, setIsChatbotPending } = useChatStateStore();
	const { setMessages } = useMessagesStore();
    const { clearExamples, clearFollowupQuestion } = useProductsStore();

	return {
        sendMessage: (messageInput, isChatbot, preAnswers = []) => {
            clearExamples();
            clearFollowupQuestion();
			sendMessages({ 
                message: messageInput, 
                itemId: currentItemId, 
                preAnswers: preAnswers,
            });
			setMessages([{ 
				message: messageInput, 
				isChatbot: isChatbot,
			}]);
		},
		isPending,
		isSuccess,
	};
}

export const useSendMessageWithWebSocket = () => {
    const { partnerId, chatUserId, isMobileDevice } = useUserStore();
    const { setMessages, streamMessage, clearStreamMessage, setStreamMessage } = useMessagesStore();
    const { setIsStreaming, connectionId, setConnectionId, currentItemId, setIsChatbotPending } = useChatStateStore();
    const { setExamples, clearFollowupQuestion } = useProductsStore();
    const { mutate: refetchProductInfo } = useFetchProductInfo();

    return useMutation({
        mutationFn: async (variables) => {
            const { messageInput, isChatbot, preAnswers = [] } = variables;
            setMessages([{ 
				message: messageInput, 
				isChatbot: false,
			}]);
            
            let messageOutput = '';
            let localConnectionId = null;
            const ws = new WebSocket(process.env.REACT_APP_WSS_CHAT);

            ws.onopen = () => {
                ws.send(JSON.stringify({
                    "action": "chat",
                    "chatUserId": chatUserId,
                    "channelId": isMobileDevice ? 'mobile' : 'web',
                    "requestMessage": messageInput,
                    "itemIds": currentItemId,
                    "preAnswers": preAnswers,
                    "externalKey": partnerId,
                    "connectionId": connectionId,
                }));
            };

            ws.onmessage = (event) => {
                const data = event.data ? JSON.parse(event.data) : null;
                if (data && data.connectionId) {
                    setConnectionId(data.connectionId);
                    localConnectionId = data.connectionId;
                    clearFollowupQuestion();
                    // clearStreamMessage();
                    // setProducts([]);
                } else if (data && data.token) {
                    setIsChatbotPending(false);
                    setStreamMessage(data.token);
                    messageOutput += data.token;
                    
                    // setMessages([{ message: messageOutput, isChatbot: true }]);
                } else if (data && data.done) {
                    refetchProductInfo(localConnectionId);
                    ws.close();
                    setMessages([{ message: messageOutput, isChatbot: true, type: 'stream' }]);
                    logToConsole({text: messageOutput});
                    setIsStreaming(false);
                    clearStreamMessage();
                    setIsChatbotPending(false);
                } else if (data && data.error) {
                    console.log('data.error', data.error);
                    setMessages([{ message: '네트워크 에러가 발생했습니다. 다시 시도해주세요.', isChatbot: true }]);
                    setIsChatbotPending(false);
                    setIsStreaming(false);
                    clearStreamMessage();
                    ws.close();
                }
            };
    
            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };
    
            ws.onerror = (error) => {
                setMessages([{ message: '네트워크 에러가 발생했습니다. 다시 시도해주세요.', isChatbot: true }]);
                setIsChatbotPending(false);
                setIsStreaming(false);
                clearStreamMessage();
                ws.close();
                console.warn('WebSocket error:', error);
            };
        }, 
        enabled: false,
    });
}

export const useSendPDPMessage = () => {
    const { partnerId, chatUserId, isMobileDevice } = useUserStore();
    const { products, setProducts, clearProducts, structuredProducts, setStructuredProducts } = useProductsStore();
    const { setMessages, clearMessages, messages } = useMessagesStore();
    const { currentItemId, setCurrentItemId, setIsChatbotPending, setIsNewProduct, setNewProductCount, displayItemId, isPDPInit, setIsPDPInit } = useChatStateStore();
    const { setIsFormSubmitted } = useChatStateStore();
    const { refetch: refetchExample } = useExampleQuery();

    return useMutation({
        mutationFn: async () => {
            try {
                setIsFormSubmitted('initial');
                const response = await chatApi.post(process.env.REACT_APP_CHAT_PRODUCT_ENDPOINT + `/${partnerId}`, {
                    itemId: String(displayItemId),
                    chatUserId: String(chatUserId),
                    channelId: isMobileDevice ? 'mobile' : 'web',
                });
                
                if (response.data.text) {
                    // setMessages([{ message: response.data.text[0], isChatbot: true }])
                    logToConsole(response.data);
                    if (response.data.product.length > 0) {
                        const newProducts = [...response.data.product, ...products];
                        const newStructuredProducts = groupProductsUsingReduce(response.data.product, 3);
                        clearProducts();
                        setProducts(newProducts);
                        setStructuredProducts(newStructuredProducts);
                        setIsNewProduct(true);
                        setNewProductCount(response.data.product.length);
                        setIsPDPInit(true);
                    }
                    setIsChatbotPending(false);
                    if (currentItemId.length > 0) {
                        refetchExample();
                    }
                    // "intent": "pdp_start_question",
                    setMessages([{ message: response.data.text[0], isChatbot: true, type: 'pdp' }]);
                    return response.data;
                }
            } catch (error) {
                setMessages([{ message: '네트워크 에러가 발생했습니다. 다시 시도해주세요.', isChatbot: true }])
                logToConsole({textResp: error.message});
                setIsChatbotPending(false);
                console.log('error', error.message);
            }
        },
        enabled: false,
    });
}

export const useFetchProductInfo = () => {
    const { partnerId, chatUserId } = useUserStore();
    const { currentItemId, setIsNewProduct, setNewProductCount, setIsChatbotPending, isChatbotPending } = useChatStateStore();
    const { products, setProducts, clearProducts, setStructuredProducts } = useProductsStore();
    const { refetch: refetchExample } = useExampleQuery();

    return useMutation({
        mutationFn: async (connectionId) => {
            let attempts = 0;
            const maxAttempts = 20;
            const retryDelay = 100;

            while (attempts < maxAttempts) {
                attempts++;
                const response = await chatApi.post(process.env.REACT_APP_CHATSTREAM_PRODUCT_ENDPOINT, {
                    externalKey: partnerId,
                    connectionId: String(connectionId),
                    chatUserId: String(chatUserId),
                });

                if (response.data.product && response.data.product.length > 0) {
                    const newProducts = [...response.data.product, ...products];
                    const newStructuredProducts = groupProductsUsingReduce(response.data.product, 3);
                    clearProducts();
                    setProducts(newProducts);
                    setStructuredProducts(newStructuredProducts);
                    setIsNewProduct(true);
                    setNewProductCount(response.data.product.length);
                    break; // Exit the loop if products are found
                }
                
                await new Promise(resolve => setTimeout(resolve, retryDelay));    
            }
            if (currentItemId.length > 0) {
                refetchExample();
            }
            setIsChatbotPending(false);
            
            // Optionally handle the case where no products were found after all attempts
            if (attempts === maxAttempts) {
                console.warn('Max attempts reached without finding products');
            }
        }, 
        enabled: false,
    });
}

export const useExampleQuery = () => {
    const { partnerId, chatUserId } = useUserStore();
    const { currentItemId, setIsChatbotPending } = useChatStateStore();
    const { setExamples, setFollowupQuestion } = useProductsStore();
    const { messages } = useMessagesStore();
    
    return useQuery({
        queryKey: ['example', partnerId, currentItemId],
        queryFn: async () => {
            if (currentItemId.length === 0) {
                return;
            }
            await new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, 3000);
            });
            
            let attempts = 0;
            const maxAttempts = 5;
            const retryDelay = 1000;

            while (attempts < maxAttempts) {
                attempts++;
                const response = await chatApi.get(process.env.REACT_APP_EXAMPLE_ENDPOINT + `/${partnerId}`, 
                    {
                        params: {
                            chatUserId: String(chatUserId),
                            partnerId: String(partnerId),
                            itemIds: currentItemId,
                        }
                    }
                );

                if (response.data.examples && response.data.examples.length > 0 && messages.length > 0) {
                    setExamples(response.data.examples);
                    setFollowupQuestion(response.data.followupQuestion);
                    logToConsole({examples: response.data.examples, itemIds: currentItemId});
                    setIsChatbotPending(false);
                    return response;
                }

                if (response.data.examples.length === 0 && attempts < maxAttempts) {
                    logToConsole({examples: response.data.examples, itemIds: currentItemId});
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                }
            }

            // Final attempt only if all retries failed
            const finalResponse = await chatApi.get(process.env.REACT_APP_EXAMPLE_ENDPOINT + `/${partnerId}`, 
                {
                    params: {
                        chatUserId: String(chatUserId),
                        partnerId: String(partnerId),
                        itemIds: currentItemId,
                    }
                }
            );
            // setExamples(finalResponse.data.examples || []);
            return finalResponse;
        },
        enabled: false,
    });
};

export const useResetQuery = () => {
    const { chatUserId, partnerType, partnerId } = useUserStore();
    const { clearMessages, setMessages } = useMessagesStore();
    const { clearProducts, setProducts, clearStructuredProducts, setStructuredProducts, clearExamples, clearFollowupQuestion } = useProductsStore();
    const { setIsPreAnswered, setIsFormSubmitted, setCurrentItemId } = useChatStateStore();
    return useMutation({
        mutationFn: async () => {
            const response = await chatApi.post(process.env.REACT_APP_RENEW_ENDPOINT + `/${partnerId}`, {
                chatUserId: String(chatUserId),
            });
            
            clearMessages();
            clearProducts();
            clearStructuredProducts();
            clearExamples();
            clearFollowupQuestion();
            setCurrentItemId([]);
            setIsPreAnswered(false);
            setIsFormSubmitted('initial');
            return response;
        },
    });
}

export const useLoadHistory = () => {
    const { partnerId, chatUserId } = useUserStore();
    const { setMessages, clearMessages } = useMessagesStore();
    const { setProducts, clearProducts, setStructuredProducts, clearStructuredProducts, clearExamples } = useProductsStore();

    return useQuery({
        queryKey: ['loadHistory', partnerId],
        queryFn: async () => {
            const response = await chatApi.get(process.env.REACT_APP_CHAT_ENDPOINT + `/load/${partnerId}`, {
                params: {
                    partnerId: String(partnerId),
                    chatUserId: String(chatUserId),
                }
            });

            const formattedHistory = response.data.chatList.map(message => ({
                message: message.text,
                isChatbot: message.role === 'assistant',
            }));
            const productList = response.data.chatList
                .filter(message => message.role === 'assistant')
                .map(message => [...message.product])
                .flat()
                .reverse(); 
            const structuredProductList = response.data.chatList
                .filter(message => message.role === 'assistant')
                .map(message => [...message.product].reverse())
                .filter(products => products.length > 0)
                .reverse();
            
            clearMessages();
            clearProducts();
            clearStructuredProducts();
            if (productList.length === 0) {
                clearExamples();
            }
            setMessages(formattedHistory);
            setProducts(productList);
            setStructuredProducts(structuredProductList);
            return response;
        },
        enabled: false,
    });
}

export const useFormSubmitted = () => {
    const { chatUserId, partnerId } = useUserStore();
    const { currentItemId } = useChatStateStore();
    const { setMessages } = useMessagesStore();
    const { utmParams } = useUtmStore();

    return useMutation({
        mutationFn: async (variables) => {
            const { formData = {} } = variables;
            const response = await chatApi.post(process.env.REACT_APP_FORM_ENDPOINT + `/${partnerId}`, 
                {
                    chatUserId: String(chatUserId),
                    channelId: 'web',
                    itemId: [String(currentItemId)],
                    formData: {
                        name: formData.name,
                        phone: formData.phone,
                        email: formData.email,
                        checkPrivacyPolicy: formData.agreements.checkPrivacyPolicy,
                        checkMarketing: formData.agreements.checkMarketing,
                    },
                },
                {
                    params: {
                        utm_source: utmParams?.source,
                        utm_medium: utmParams?.medium,
                        utm_campaign: utmParams?.campaign,
                        utm_content: utmParams?.content,
                        utm_term: utmParams?.term,
                        transitions_page: utmParams?.transitionPage,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                
            );
            setMessages([{ message: response.data.text[0], isChatbot: true }])
            return response.text;
        },
    });
}

export const useUserEvent = () => {
    const { chatUserId, partnerId, isMobileDevice } = useUserStore();

    return useMutation({
        mutationFn: async (variables) => {
            const response = await chatApi.post(process.env.REACT_APP_USEREVENT_ENDPOINT + `/${partnerId}`, {
                channelId: String(isMobileDevice ? 'mobile' : 'web'),
                eventCategory: String(variables.eventCategory),
                chatUserId: String(chatUserId),
                products: [],
            });
            return response;
        },
    });
}