import { useEffect, useState } from "react";
import { useChatbotStore, useChatStateStore, useFormStore, useMessagesStore } from "../zustand/store";
import { ChatBubble } from "./ChatBubble";
import { useSendMessageWithWebSocket } from "../services/queries/chatbotQueries";
import styles from '../style/styles/PreQuestion.module.css';
import { sendInputFocusStatus } from "../utils/utils";
const PreQuestion = () => {
    const { chatbot } = useChatbotStore();
    const { isPreAnswered, setIsPreAnswered, setIsChatbotPending, setIsStreaming } = useChatStateStore();
    const { mutate: sendMessageWithWebSocket, onSuccess: onSuccess } = useSendMessageWithWebSocket();
    const [count, setCount] = useState(0);
    const [ preAnswers, setPreAnswers ] = useState([]);

    useEffect(() => {
        if (preAnswers.length === chatbot.preQuestion.questions.length) {
            // sendMessage('조건에 맞는 사무실을 추천해주세요', false, preAnswers);
            setIsStreaming(true);
            sendMessageWithWebSocket({ 
                messageInput: '조건에 맞는 사무실을 추천해주세요', 
                isChatbot: false, 
                preAnswers: preAnswers,
            });
            setIsChatbotPending(true);
            setCount(0);
            setPreAnswers([]);
            setIsPreAnswered(true);
        }
    }, [preAnswers]);

    return (
        !isPreAnswered &&
        <div style={{width: '100%', height: 'fit-content'}}>
            {
                chatbot.preQuestion.questions.map((question, index) => {
                    if (index === count) {
                    return (
                        <>
                            <ChatBubble message={{message: question.question, isChatbot: true}} />
                            {
                                question.inputType === 'Text' ?
                                <TextInput order={index} question={question} count={count} setCount={setCount} preAnswers={preAnswers} setPreAnswers={setPreAnswers} /> :
                                question.inputType === 'MultiSelect' ?
                                <MultiSelect order={index} question={question} count={count} setCount={setCount} preAnswers={preAnswers} setPreAnswers={setPreAnswers} /> :
                                null
                            }
                        </>
                        )
                    }
                })
            }
        </div>
    )
}

const TextInput = (props) => {
    const { order, question, count, setCount, preAnswers, setPreAnswers } = props;
    const { setMessages } = useMessagesStore();
    const { preAnswersObject, setPreAnswersObject } = useFormStore();
    const [userInput, setUserInput] = useState('');
    const [inputValid, setInputValid] = useState(false);
    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
        if (e.target.value.length > 0) {
            setInputValid(e.target.value > 0 && e.target.value < 1000);
        }
    }

    const handleButtonClick = () => {
        if (!inputValid) return;
        if (Number(userInput) > 999 || Number(userInput) < 1) return;
        setMessages([{message: question.question, isChatbot: true}]);
        setMessages([{message: userInput, isChatbot: false}]);
        setPreAnswers(preAnswers.concat(userInput));
        setPreAnswersObject({
            ...preAnswersObject,
            [order]: userInput,
        });
        setCount(count + 1);
    }

    const handleKeyPress = async (e) => {
        e.stopPropagation();
        if (!inputValid) return;
        if (Number(userInput) > 999 || Number(userInput) < 1) return;
        if (e.key === 'Enter') {
            e.preventDefault();
            handleButtonClick();
            setUserInput('');
        }
    };

    return (
        <div 
            style={{
                width: '100%', 
                height: 'fit-content', 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'flex-end',
                padding: '10px 16px',
            }}
        >  
            <div 
                style={{
                    width: 'fit-content', 
                    height: 'fit-content', 
                    border: '1px solid #e1e1e1', 
                    borderRadius: '10px', 
                    padding: '10px', 
                    paddingRight: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <label className="h5 fc-gray-600">인원 수 입력</label>
                <div className="spacing-8" />
                <input 
                    type="text" 
                    className={styles.PreQuestion__input}
                    placeholder="인원 수를 입력해 주세요" 
                    onChange={handleUserInputChange}
                    onKeyDown={handleKeyPress}
                    onFocus={() => sendInputFocusStatus(true)}
                />
                {
                    (!inputValid && userInput.length > 0) &&
                    <>
                        <div className='spacing-6' />
                        <div className={styles.PreQuestion__warning}>
                            <img src='/img/units/cancel.png' alt='cancel' width={16} height={16} />
                            <p className='h9 fc-warning'>1~999명까지 입력 가능합니다.</p>
                        </div>
                    </>
                }
            </div>
            <button 
                onClick={handleButtonClick}
                className={inputValid ? styles.PreQuestion__submitButton : styles.PreQuestion__submitButton__disabled}
            >
                <p className="h1 fc-white">입력 완료</p>
            </button>
        </div>
    )
}

const MultiSelect = (props) => {
    const { order, question, count, setCount, preAnswers, setPreAnswers } = props;
    const { setMessages } = useMessagesStore();
    const { preAnswersObject, setPreAnswersObject } = useFormStore();
    const [checked, setChecked] = useState(Array(question.options.length).fill(false));
    const [selected, setSelected] = useState([]);
    const [inputValid, setInputValid] = useState(false);
    
    const handleChange = (e) => {
        const index = e.target.dataset.index;
        const newChecked = [...checked];
        newChecked[index] = e.target.checked;
        setChecked(newChecked);
    }

    useEffect(() => {
        let newSelected = [];
        question.options.forEach((option, index) => {
            if (checked[index]) {
                newSelected.push(option);
            }
        });
        setSelected(newSelected);
        setInputValid(newSelected.length > 0);
    }, [checked]);

    const handleButtonClick = () => {
        if (!inputValid) return;
        setMessages([{message: question.question, isChatbot: true}]);
        setMessages([{message: selected.join(), isChatbot: false}]);
        setPreAnswers(preAnswers.concat(selected.join()));
        setPreAnswersObject({
            ...preAnswersObject,
            [order]: selected.join(),
        });
        setCount(count + 1);
    }

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: '0 16px'}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '8px'}}>
                {
                    question.options.map((option, index) => (
                        <>
                            <label 
                                htmlFor={option}
                                // style={{
                                //     padding: '14px 16px',
                                //     border: `${checked[index] ? '1px solid #154cca' : '1px solid #e1e1e1'}`,
                                //     borderRadius: '999px',
                                //     cursor: 'pointer',
                                // }}
                                className={checked[index] ? styles.MultiSelect__option__checked : styles.MultiSelect__option}
                            >
                                <p className="h6 fc-gray-800">{option}</p>
                            </label>
                            <input 
                                name={option} type="checkbox" id={option} hidden 
                                data-index={index}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                            />
                        </>
                    ))
                }
            </div>
            <button 
                onClick={handleButtonClick}
                className={inputValid ? styles.PreQuestion__submitButton : styles.PreQuestion__submitButton__disabled}
            >
                <p className="h1 fc-white">선택 완료</p>
            </button>
        </div>
    )
}

export default PreQuestion;