import { useEffect, useState } from "react";
import RecommendSection from "./SDK/RecommendSection";
import MarkDownConverter from "./Markdown";
import axios from "axios";

export const ModuleTest = () => {
    const [message, setMessage] = useState('');
    const [products, setProducts] = useState([]);

    return (
        <div style={{ width: '100%', padding: '20px' }}>
            <TestModule message={message} setMessage={setMessage} setProducts={setProducts} />
            {/* <div style={{width: '100%'}}>
                <RecommendSection />
            </div> */}
            <hr />
            <div>
                <MarkDownConverter text={message} />
            </div>
            <hr />
            <div>
                {products.map((item, index) => (
                    <div key={index} style={{ width: '100%', padding: '10px', background: 'lightgray', marginBottom: '10px' }}>
                        <div>name: {item.name}</div>
                        <div>itemId: {item.itemId}</div>
                        <div>price: {item.price}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const TestModule = (props) => {
    const { message, setMessage, setProducts } = props;
    let connectionId = '';
    const [inputMessage, setInputMessage] = useState('');
    const [isChatbotPending, setIsChatbotPending] = useState(false);
    const chatUserId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    // webSocket test

    const fetchProductInfo = async (connectionId) => {
        for (let i = 0; i < 10; i++) {
            try {
                await new Promise(resolve => {
                    setTimeout(() => {
                        resolve();
                    }, 3000);
                });
                const response = await axios.post(`https://dev-api.gentooai.com/chat/api/v1/chat-stream/product`, {
                    "externalKey": "676a4b3cac97386117d1838d",
                    "connectionId": connectionId,
                });
                console.log('response', response);
                if (response.data.product) {
                    console.log('response.data.product', response.data.product);
                    return response.data;
                }
                setTimeout(() => { }, 100);
            } catch (error) {
                console.error('Error fetching product info:', error);
                alert('Error fetching product info');
            }
        }
    }


    const ws = new WebSocket('wss://dev-chat.gentooai.com');

    const sendMessage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isChatbotPending) return;
        // ws.onopen = () => console.log("WebSocket Connected");
        
        ws.send(JSON.stringify({
            "action": "chat",
            "chatUserId": chatUserId,
            "channelId": 'web',
            "requestMessage": inputMessage,
            "itemIds": [],
            "preAnswers": [],
            "externalKey": "676a4b3cac97386117d1838d"
        }));

        ws.onmessage = (event) => {
            const data = event.data ? JSON.parse(event.data) : null;
            console.log('data', event.data);
            if (data && data.connectionId) {
                console.log('data.connectionId', data.connectionId);
                connectionId = data.connectionId;
                setMessage('');
                setProducts([]);
            } else if (data && data.token) {
                console.log('data.token', message + data.token);
                setMessage((prev) => prev + data.token);
            } else if (data && data.done) {
                fetchProductInfo(connectionId)
                    .then(res => {
                        setProducts(res.product);
                        ws.close();
                        setIsChatbotPending(false);
                    })
                setIsChatbotPending(false);
                ws.close();
            } else if (data && data.error) {
                console.log('data.error', data.error);
                setIsChatbotPending(false);
                ws.close();
                alert(data.error.message);
            }
        }

        ws.onclose = () => {
            console.log('WebSocket connection closed');
        }

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        }
        // setInputMessage('');
        // setMessage('');
    }

    const handleKeyUp = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            setIsChatbotPending(true);
            e.preventDefault();
            sendMessage(e);
        }
    }

    return (
        <>
            <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyUp={handleKeyUp}
                style={{ width: '500px', marginRight: '10px', padding: '10px' }}
            />
            <button
                onClick={sendMessage}
                style={{ padding: '10px' }}
            >Send Message</button>
        </>
    )
}